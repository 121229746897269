import React from 'react';
import Layout, { TEMPLATE } from '../../components/Layout';

const About = () => (
  <Layout title="About" template={TEMPLATE.TEXT}>
    <h1>About Me</h1>
    <p>
      I'm a startup-friendly, one-man design and front-end department, a hybrid
      role l’ve been increasingly playing in recent years. I think my core
      differentiator is that my skills span the spectrum between UX/interaction
      design (wireframes, user flows), visual/UI design (mockups), asset
      production, web template development (HTML, CSS), front-end web
      development (JavaScript, React, Typescript, JSON), and static site gen
      templating (Jekyll, Gatsby). I also have experience project managing and
      copywriting.
    </p>
    <p>
      Depending on what’s needed, I can focus on any of the above as a
      specialty, or I can go from having a brainstorm with product owners and
      engineers about business and technical requirements, to independently
      delivering a polished, branded, functional, production-ready web
      front-end/view layer (with as much check-in/input/course correction along
      the way as needed).
    </p>
    <p>
      As a consultant over the past 14 years, I’ve had the opportunity to work
      with many different organizations, teams, projects, processes, and
      industries while wearing lots of hats. I think this has given me a unique
      perspective and ability to ramp up and adapt quickly to new work
      environments. I’ve done pixel-perfect, siloed waterfall on huge projects
      with large, international teams, and I’ve done ultra-lean, iterative,
      scrappy MVP as part of a skeleton crew where we’d release to prod
      frequently, sometimes more than once a day.
    </p>
  </Layout>
);

export default About;
